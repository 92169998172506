<ng-container [ngSwitch]="appStatus$ | async">

    <ng-container *ngSwitchCase="'LOADING_CONFIG'">
        <ng-container *ngTemplateOutlet="spinner"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'APP_RELOADING'">
        <ng-container *ngTemplateOutlet="spinner"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'APP_UNAVAILABLE'">
        <app-unavailable [message]="unavailabilityMessage$ | async"></app-unavailable>
    </ng-container>

    <ng-container *ngSwitchCase="'APP_RELOAD_FAILED'">
        <app-unavailable message="Oups ! Nous n'arrivons pas à ouvrir votre application dans sa dernière version. Veuillez réessayer ultérieurement."></app-unavailable>
    </ng-container>

    <ng-container *ngSwitchCase="'READY'">
        <router-outlet></router-outlet>
    </ng-container>


</ng-container>

<ng-template #spinner>
    <div class="fullscreen-wrapper">
        <nz-spin nzSimple nzSize="large"></nz-spin>
    </div>
</ng-template>