import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '@services/configuration.service';
import { tap, map, Observable, shareReplay, BehaviorSubject } from 'rxjs';
import { AppAvailability } from '@assets/models/configuration/appAvailability.model';

type AppStatus = 'LOADING_CONFIG' | 'READY' | 'APP_UNAVAILABLE' | 'APP_RELOADING' | 'APP_RELOAD_FAILED';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
  private appStatus: BehaviorSubject<AppStatus> = new BehaviorSubject<AppStatus>('LOADING_CONFIG');
  public appStatus$: Observable<AppStatus> = this.appStatus.asObservable();

  private appAvailabilityFlagValue$: Observable<AppAvailability> = this.configService.getFlagValue$<AppAvailability>('appAvailability', false).pipe(
    shareReplay(1),
  );

  unavailabilityMessage$: Observable<string | undefined> = this.appAvailabilityFlagValue$.pipe(
    map(({ message }: { message?: string }) => message),
  );

  constructor(private configService: ConfigurationService) {
  }

  ngOnInit(): void {
    this.appAvailabilityFlagValue$
      .pipe(
        tap(({ enabled, message, clientReloadNeeded }) => {
          if (!this.configService.isReady()) {
            this.appStatus.next('LOADING_CONFIG');
            return;
          }

          if (!enabled) {
            this.appStatus.next('APP_UNAVAILABLE');
            return;
          }

          if (clientReloadNeeded) {
            if (this.configService.shouldRetryReloadNow()) {
              this.appStatus.next('APP_RELOADING');
              this.configService.incrementReloadRetriesCount();
              window.location.reload();
            } else {
              this.appStatus.next('APP_RELOAD_FAILED');
              this.configService.resetReloadRetriesCount();
            }

            return;
          }

          this.appStatus.next('READY');
          this.configService.resetReloadRetriesCount();
        })
      )
      .subscribe();
  }

}
