import { Injectable } from '@angular/core';
import { CallerService } from '@services/caller.service';
import { BehaviorSubject, map, Observable, switchMap, tap, timer } from 'rxjs';
import { getFlagValueFromConfig } from '@assets/utils/configuration/featureFlags';
import { FeatureFlagsConfig, FeatureFlagsConfigValue } from '@assets/models/configuration/featureFlags.model';

const REFRESH_RATE: number = 5 * 60 * 1000; // 5 minutes
const APP_RELOAD_MAX_RETRIES: number = 3;
const APP_RELOAD_RETRIES_KEY: string = 'APP_RELOAD_RETRIES_COUNT';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private featureFlags: BehaviorSubject<FeatureFlagsConfig> = new BehaviorSubject<FeatureFlagsConfig>({});
  private lastUpdate: number = 0;

  constructor(private callerService: CallerService) {
    this.startPolling();
  }

  public getFlagValue$<T extends FeatureFlagsConfigValue = FeatureFlagsConfigValue>(flagName: string, defaultValue: boolean = false): Observable<T> {
    return this.featureFlags
      .pipe(
        map(_ => this.getFlagValue<T>(flagName, defaultValue) as T),
      );
  }

  public getFlagValue<T extends FeatureFlagsConfigValue = FeatureFlagsConfigValue>(flagName: string, defaultValue: boolean = false): T {
    return getFlagValueFromConfig<T>(this.featureFlags.getValue(), flagName, defaultValue);
  }

  public isReady(): boolean {
    return this.lastUpdate > 0;
  }

  private startPolling(): void {
    let requestTimestamp: number;
    timer(0, REFRESH_RATE)
      .pipe(
        tap(_ => requestTimestamp = Date.now()),
        switchMap(_ => this.getFeatureFlags$()),
        tap(flags => {
          this.lastUpdate = requestTimestamp;
          this.featureFlags.next(flags);
        }),
      )
      .subscribe();
  }

  public getReloadRetriesCount(): number {
    const appReloadRetriesCount = Number(localStorage.getItem(APP_RELOAD_RETRIES_KEY));

    if (isNaN(appReloadRetriesCount)) {
      return 0;
    }

    return appReloadRetriesCount;
  }

  public shouldRetryReloadNow(): boolean {
    const appReloadRetriesCount = this.getReloadRetriesCount();

    return appReloadRetriesCount < APP_RELOAD_MAX_RETRIES;
  }

  public incrementReloadRetriesCount(): void {
    const appReloadRetriesCount = this.getReloadRetriesCount();

    localStorage.setItem(APP_RELOAD_RETRIES_KEY, (appReloadRetriesCount + 1).toString());
  }

  public resetReloadRetriesCount(): void {
    localStorage.setItem(APP_RELOAD_RETRIES_KEY, '0');
  }

  private getFeatureFlags$(): Observable<FeatureFlagsConfig> {
    return this.callerService.callable('getFeatureFlags', { configGroup: 'BOA' });
  }
}
